<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav right-nav align-items-center ml-auto">
      <Language/>
    
      <li class="">
        <div class="mode-toggle" @click="toggleMode">
          <span :title="$t('Light Mode')" :class="{ active: !isDarkMode }">
            <ElementIcon v-bind:icon="'sunIcon'" />
          </span>
          <span :title="$t('Dark Mode')" :class="{ active: isDarkMode }">
            <ElementIcon v-bind:icon="'moonIcon'" />
          </span>
        </div>
      </li>

      <b-nav-item-dropdown
        right
        class="notification"
        title="Notification"
        menu-class="dropdown-lg custom-dropdown"
      >
        <template
          slot="button-content"
          class="topnav-menu-link dropdown-toggle"
        >
          <div class="notification-btn">
            <div class="noti-badge"></div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icons">
                <mask
                  id="mask0_1182_2950"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill="#D9D9D9"
                  />
                </mask>
                <g mask="url(#mask0_1182_2950)">
                  <path
                    id="notifications"
                    d="M5 19C4.71667 19 4.479 18.904 4.287 18.712C4.09567 18.5207 4 18.2833 4 18C4 17.7167 4.09567 17.4793 4.287 17.288C4.479 17.096 4.71667 17 5 17H6V10C6 8.61667 6.41667 7.38733 7.25 6.312C8.08333 5.23733 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.646 2.72933 10.938 2.438C11.2293 2.146 11.5833 2 12 2C12.4167 2 12.7707 2.146 13.062 2.438C13.354 2.72933 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.23733 16.75 6.312C17.5833 7.38733 18 8.61667 18 10V17H19C19.2833 17 19.5207 17.096 19.712 17.288C19.904 17.4793 20 17.7167 20 18C20 18.2833 19.904 18.5207 19.712 18.712C19.5207 18.904 19.2833 19 19 19H5ZM12 22C11.45 22 10.9793 21.8043 10.588 21.413C10.196 21.021 10 20.55 10 20H14C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22Z"
                    fill="#11253E"
                  />
                </g>
              </g>
            </svg>
          </div>
        </template>
        <div class="notification-list-dropdown">
          <div class="notification-list-dropdown-header">
            <h5 class="m-0">
              {{ $t("Notifications") }}
            </h5>
          </div>
          <div class="py-3">
            <img
              class="d-block mx-auto"
              src="@/assets/images/no-notify-icon1.png"
              alt="Logo"
              height="190px"
              width="200px"
            />
            <p class="text-muted text-center">
              {{ $t("No Notification Yet") }}!
            </p>
          </div>
        </div>
      </b-nav-item-dropdown>
      <li class="divider"></li>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name"></p>
            <p v-if="user" class="user-status">
              {{ user.first_name }}
              <span>{{ user.last_name }}</span>
            </p>
          </div>

          <b-avatar
            size="40"
            variant="light-primary"
            badge
            v-if="user && user.profile_image"
            :src="user.profile_image"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            size="40"
            v-else
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/undefined.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          v-if="$can('settings.list')"
          link-class="d-flex align-items-center"
          @click="openSettings"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span> {{ $t("Settings") }} </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
            @click="logout"
          />
          <span>{{ $t("Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { mapGetters } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BImg,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Language from "@/components/Language.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    ElementIcon,
    Language
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  mounted() {
    // const savedMode = localStorage.getItem("isDarkMode");
    // if (savedMode !== null) {
    //   this.isDarkMode = savedMode === "true";
    // }
    const savedMode = localStorage.getItem("isDarkMode");
    this.isDarkMode = savedMode === "true"; // Convert string to boolean
    this.applyBodyClass();
  },
  async beforeCreate() {
    this.$store.dispatch("auth/show", {
      id: localStorage.getItem("user_id"),
    });
  },

  methods: {
    applyBodyClass() {
      document.body.classList.toggle("dark-layout", this.isDarkMode);
    },
    toggleMode() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem("isDarkMode", this.isDarkMode);
      this.applyBodyClass();
    },
    openSettings() {
      this.$router.push("/settings");
    },
    async logout() {
      localStorage.clear();
      localStorage.setItem("isDarkMode", this.isDarkMode);
      localStorage.setItem("user", "{}");
      localStorage.setItem("token", "");
      localStorage.setItem("refresh_token", "");
      localStorage.setItem("authenticated", false);
      localStorage.setItem("userProfileImage", "");
      this.$store.commit("permissions/permissions", []);
      this.$store.commit("auth/set_user", {});
      this.$store.commit("auth/userPermissions", []);
      this.$store.commit("permissions/permissionsGlobal", []);

      this.$router.push("/");
    },
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters("auth", ["user"]),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "Arabic",
      },
      {
        locale: "ch",
        img: require("@/assets/images/flags/ch.jpg"),
        name: "Chinese",
      },
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "fr",
        img: require("@/assets/images/flags/fr.png"),
        name: "French",
      },
      {
        locale: "de",
        img: require("@/assets/images/flags/de.png"),
        name: "German",
      },
      {
        locale: "ja",
        img: require("@/assets/images/flags/ja.png"),
        name: "Japanese",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Spanish",
      },
      {
        locale: "tu",
        img: require("@/assets/images/flags/tu.png"),
        name: "Turkish",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style lang="scss">
.terms-modal {
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lang {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    p {
      color: #000;
    }
  }
}
.dark-layout {
  .lang {
    ul {
      p {
        color: #fff;
      }
    }
  }
}
.content-de {
  height: auto !important;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
</style>
