export default [
  // {
  //   header: "Home",
  // },
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Customers",
    route: "customers",
    icon: "InfoIcon",
    permission: "company.list",
  },
  {
    title: "Case List",
    route: "my-cases",
    icon: "ListIcon",
    permission: "case-instance.list",
  },
  {
    title: "Coupon Codes",
    route: "coupon-codes",
    icon: "InfoIcon",
    permission: "coupon-code.list",
  },
  {
    title: "News",
    route: "news",
    icon: "InfoIcon",
    permission: "news.list",
  },
  {
    title: "Invoices",
    route: "invoices",
    icon: "FileTextIcon",
    permission: "invoice.list",
  },
  {
    title: "Global Configuration",
    route: "global-configuration",
    icon: "SettingsIcon",
    permission: "global-configuration.list",
  },
  {
    title: "Credits Packages",
    route: "packages",
    icon: "ShoppingCartIcon",
    permission: "package.list",
  },
  {
    title: "Payment History",
    route: "payment-history",
    icon: "ClockIcon",
    // permission: "package.list",
  },
  {
    title: "Ticket System",
    route: "tickets",
    icon: "CheckSquareIcon",
    permission: "ticket.list",
  },
  {
    title: "Referrals",
    route: "referral",
    icon: "InfoIcon",
    permission: "referral.list",
  },
  /*
  {
    title: 'Packages',
    route: 'packages',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  },*/
  // {
  //   header: "Storage",
  // },
  // {
  //   title: "Case List",
  //   route: "case-list",
  //   icon: "ListIcon",
  // },
  /*{
    labels: ["cloud-storage"],
    title: 'Cloud Storage',
    route: 'cloud-storage',
    icon: 'HardDriveIcon',
  },*/
  // {
  //   header: "Extensions",
  // },
  // {
  //   title: "2D to 3D",
  //   route: "2d-to-3d",
  //   icon: "CpuIcon",
  //   labels: ["2d3d-extension"],
  // },
];
